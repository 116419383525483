import React, { forwardRef, useImperativeHandle } from "react";
import {
Card,
CardHeader,
CardBody,
Row,
Col,
CardTitle
} from "reactstrap";
import Loader from '../services/loader';
import TopNavDropdown from './TopDropdown';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';

const selectStyle = {
  width: '80px',
  height: '30px',
  padding: '2px 5px',
  border: '1px solid #e3e3e3',
  borderRadius: '4px',
  backgroundColor: 'transparent',
  color: 'inherit',
  fontSize: 'inherit',
  margin: '0 auto',  // Center the select element horizontally
  display: 'block'   // Makes margin auto work  
  };

  class ReportSequence extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            sequenceData: [],
            updatedSequences: {},
            isInitialized: false
        };
  
        // Bind methods in constructor
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleSequenceChange = this.handleSequenceChange.bind(this);
        this.getReportSequence = this.getReportSequence.bind(this);
    }    
    
    // Separate method for initial data fetch
    initializeData = (customerId) => {
        console.log('InitializeData called with:', {
            providedCustomerId: customerId,
            propsCustomerId: this.props.selectedcustomer,
            storedCustomerId: localStorage.getItem('selectedCustomer'),
            activeTab: this.props.activeTab
        });
      
        if (customerId && this.props.activeTab === '3') {
            this.getReportSequence(customerId);
        }
      }    
      componentDidMount() {
        console.log('ReportSequence Mount - Environment:', process.env.NODE_ENV);
        console.log('Initial props:', this.props);
        console.log('localStorage customer:', localStorage.getItem('selectedCustomer'));
      
        // Try to get customer ID from multiple sources
        const customerId = this.props.selectedcustomer || 
                          localStorage.getItem('selectedCustomer');
      
        if (customerId) {
            console.log('Found customer ID:', customerId);
            this.initializeData(customerId);
        } else {
            console.log('No customer ID found');
            // Maybe try to get it from your global state/Redux if available
        }
      }
        
    componentDidUpdate(prevProps) {
        console.log('ReportSequence - componentDidUpdate', {
            prevCustomer: prevProps.selectedcustomer,
            currentCustomer: this.props.selectedcustomer,
            prevTab: prevProps.activeTab,
            currentTab: this.props.activeTab
        });

 // If customer changed
      if (prevProps.selectedcustomer !== this.props.selectedcustomer && this.props.selectedcustomer) {
          console.log('Customer changed, calling getReportSequence');
          this.getReportSequence(this.props.selectedcustomer);
      }

      // If tab changed to 3 and we have a customer
      if (prevProps.activeTab !== '3' && this.props.activeTab === '3' && this.props.selectedcustomer) {
          console.log('Tab changed to 3, calling getReportSequence');
          this.getReportSequence(this.props.selectedcustomer);
      }
  }    // Add this method to force a refresh
    forceRefresh() {
        if (this.props.selectedcustomer) {
            this.getReportSequence();
        }
    }

    getReportSequence = (customerId) => {
        if (!customerId) {
            console.log('No customer ID provided, skipping API call');
            return;
        }
  
        console.log('Calling GetReportSequence API for customer:', customerId);
        this.setState({ loader: true });
  
        apiService.GetReportSequence(customerId)
            .then(response => {
                console.log('API Response received:', response);
                this.setState({
                    sequenceData: response.data,
                    loader: false,
                    isInitialized: true
                });
            })
            .catch(error => {
                console.error('API Error:', error);
                this.setState({ loader: false });
                apiService.ErrorMessage(error);
            });
    }    
    
    // New method to get default customer
    getDefaultCustomer() {
      // Assuming you have a method in TopNavDropdown to get the default customer
      const defaultCustomer = localStorage.getItem('defaultCustomer');
      if (defaultCustomer) {
        this.setState({ selectedCustomer: defaultCustomer }, () => {
          this.getReportSequence();
        });
      }
    }

    // customerHandler(customerId) {
    //   localStorage.setItem('selectedCustomer', customerId); // Save to localStorage
    //   this.setState({ selectedCustomer: customerId }, () => {
    //     this.getReportSequence();
    //   });
    // }
    
 handleSequenceChange(boardId, newSequence) {
      this.setState(prevState => ({
          updatedSequences: {
              ...prevState.updatedSequences,
              [boardId]: newSequence
          }
      }));
  }

  handleUpdate() {
    // if (!this.props.selectedcustomer) {  // Changed from this.state.selectedCustomer
    //     apiService.ErrorMessage("Please select a customer");
    //     return;
    // }

    const { selectedcustomer } = this.props;

    if (!selectedcustomer) {
        apiService.ErrorMessage("Please select a customer");
        return;
    }  
    const payload = [
        {
            "customer_data": {
                "customer_id": selectedcustomer
            }
        },
        ...this.state.sequenceData.map(item => ({
            "board_id": item.board_id,
            "sequence": parseInt(this.state.updatedSequences[item.board_id] || item.sequence)
        }))
    ];

 this.setState({ loader: true });    
 
 apiService.UpdateReportSequence(payload)
 .then(response => {
     this.setState({ loader: false });
     if (response && response.data && response.data[0]) {
         apiService.Alerts(response.data[0].message);
     } else {
         apiService.Alerts("Sequence updated successfully");
     }
     this.getReportSequence(selectedcustomer); // Pass the customer ID
 })
 .catch(error => {
     this.setState({ loader: false });
     if (error.response && error.response.data && error.response.data[0]) {
         apiService.Alerts_error(error.response.data[0].message);
     } else {
         apiService.Alerts_error("Error updating sequence");
     }
 });
}

render() {
      return (
          <>
{this.state.loader && <Loader />}
              <Row>
                  <Col md="12">
                      <Card className={"card-chart card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                          <CardHeader>
                              <Row>
                                  <Col md="6">
                                      <CardTitle tag="h5">Report Sequence</CardTitle>
                                  </Col>
                                  <Col md="6" className="text-right">
                                      <button 
                                          className="btn btn-primary"
                                          onClick={this.handleUpdate}  // Use the bound method
                                      >
                                          UPDATE SEQUENCE
                                      </button>
                                  </Col>
                              </Row>
                          </CardHeader>                              
                          <CardBody>
                                  <div className={"fixTableHead table-responsive " + (this.props.theme) + ' ' + (this.props.themecolor)} style={{height:"700px"}}>
                                      <table className={"nbztable dataTable no-footer " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                          <thead>
                                          <tr>
                                            <th style={{ width: '20px' }}>Board ID</th>
                                            <th style={{ width: '200px' }}>Description</th>
                                            <th style={{ width: '20px', textAlign: 'center' }}>Sequence</th> {/* Center align the header */}
                                        </tr>                                          
                                        </thead>
                                          <tbody>
                                              {this.state.sequenceData.map((value, index) => (
                                                  <tr key={index}>
                                                      <td>{value.board_id}</td>
                                                      <td>{value.equipments}</td>
                                                      <td style={{ textAlign: 'center' }}> 
                                                      <select
                                                        style={selectStyle} 
                                                        className="form-control"
                                                        value={this.state.updatedSequences[value.board_id] || value.sequence}
                                                        onChange={(e) => this.handleSequenceChange(value.board_id, e.target.value)}
                                                    >
                                                        {[...Array(this.state.sequenceData.length)].map((_, i) => (
                                                            <option key={i + 0} value={i + 0}>
                                                                {i + 0}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    </td>
                                                  </tr>
                                              ))}
                                          </tbody>
                                      </table>
                                  </div>
                              </CardBody>
                          </Card>
                      </Col>
                      </Row>
          </>
      );
  }
}
const mapStateToProps = (state) => {
return {
  theme: state.theme,
  themecolor: state.themecolor
}
}

export default connect(mapStateToProps)(ReportSequence);