import React from "react";
import "../assets/css/mui-multiselect.css"
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col, TabContent, TabPane, Nav, NavItem, NavLink
} from "reactstrap";
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { Checkbox, FormControl, ListItemIcon, ListItemText } from "@material-ui/core"
import TextField from '@material-ui/core/TextField';
import Loader from '../services/normalloader';
import * as apiService from '../services/apiService';
import { connect } from 'react-redux';
import TopNavDropdown from './TopDropdown';
import classnames from 'classnames';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Moment from 'moment';
import {
    FormBuilder,
    FieldGroup,
    FieldControl,
    Validators,
} from "react-reactive-form";
import swal from 'sweetalert';
import { MenuProps, useStyles } from "./utils";
import ReportSequence from './ReportSequence';

export class Reports extends React.Component {

    constructor(props) {
        super();
        //const [selected, setSelected] = useState([]);
        //const newclass= useStyles();
        this.state = {
            loader: false,
            showcontent: false,
            activetab: localStorage.getItem('reportsActiveTab') || '1',
            sequenceInitialized: false,
            selectedCustomer: localStorage.getItem('selectedCustomer') || '',             
            production_process_list: [],
            selectedprocess: [],
            daily_production_process_list: [],
            daily_selectedprocess: [],
            shiftlist: [
                { 'name': 'General', 'value': 'General' },
                { 'name': 'Shift 1', 'value': 'shift1' },
                { 'name': 'Shift 2', 'value': 'shift2' },
                { 'name': 'Shift 3', 'value': 'shift3' }
            ],
            selectedshift: ['General', 'shift1', 'shift2', 'shift3'],
            //classes: newclass,
            isAllDailyProcessSelected: false,
            isAllShiftProcessSelected: false,
        }
        this.customerhandler = this.customerhandler.bind(this)
        this.boardhandler = this.boardhandler.bind(this)
        this.selectedcustomer = ''
        this.selectedboard = ''
        this.pri_list = [];
        this.daily_pri_list = [];
        this.shift_list = [
            { 'name': 'General', 'value': 'General' },
            { 'name': 'Shift 1', 'value': 'shift1' },
            { 'name': 'Shift 2', 'value': 'shift2' },
            { 'name': 'Shift 3', 'value': 'shift3' }
        ];
    }

    shiftreportForm = FormBuilder.group({
        date: [""],
        shift: [['General', 'shift1', 'shift2', 'shift3']],
        meter: [[]],

    });

    dailyreportForm = FormBuilder.group({
        start_date: [""],
        end_date: [""],
        start_time: ['00:00'],
        end_time: ['23:59'],
        meter: [[]],

    });

    componentDidMount() {
        console.log('Reports - componentDidMount');
        console.log('Initial localStorage state:', {
            token: localStorage.getItem('token'),
            selectedCustomer: localStorage.getItem('selectedCustomer'),
            activeTab: localStorage.getItem('reportsActiveTab')
        });
      
        if (localStorage.getItem('token')) {
            this.convertdate1(new Date());
            console.log('After convertdate1 - selectedcustomer:', this.selectedcustomer);
            this.props.history.push('/neurobotz/reports');
        } else {
            this.props.history.push('/neurobotz/login');
        }
      }

          customerhandler(customerId) {
            console.log('customerhandler called with:', customerId);
            this.selectedcustomer = customerId;
            localStorage.setItem('selectedCustomer', customerId); // Ensure customer ID is stored        
            this.setState({
            selectedCustomer: customerId,
            showcontent: false,
            production_process_list: [],
            selectedprocess: [],
            daily_production_process_list: [],
            daily_selectedprocess: [],
            selectedshift: ['General', 'shift1', 'shift2', 'shift3'],
        }, () => {
            console.log('State updated with customer:', this.state.selectedCustomer);
        });
        this.pri_list = [];
        this.daily_pri_list = [];
        this.shiftreportForm.patchValue({
            meter: [],
            shift: ['General', 'shift1', 'shift2', 'shift3']
        })
        this.dailyreportForm.patchValue({
            meter: []
        })
    }
    boardhandler(board_id) {
        this.selectedboard = board_id
        this.getboardsfordropdown(localStorage.getItem('selectedcustomer'))
        this.setState({
            showcontent: true,
            production_process_list: [],
            selectedprocess: [],
            daily_production_process_list: [],
            daily_selectedprocess: [],
            selectedshift: ['General', 'shift1', 'shift2', 'shift3'],
        })
        this.pri_list = [];
        this.daily_pri_list = [];
        this.shiftreportForm.patchValue({
            meter: [],
            shift: ['General', 'shift1', 'shift2', 'shift3']
        })
        this.dailyreportForm.patchValue({
            meter: []
        })
    }

    getboardsfordropdown(selectedcustomer) {
        this.setState({ loader: true })
        apiService.Boarddropdowndata(selectedcustomer).then(response => {
            this.setState({ loader: false, production_process_list: response.data, daily_production_process_list: response.data })
            this.pri_list = response.data
            this.daily_pri_list = response.data;
            var finalarray = []
            for (let i = 0; i <= response.data.length - 1; i++) {
                finalarray.push(response.data[i]['id'])
            }
            this.shiftreportForm.patchValue({
                meter: finalarray,
                shift: ['General', 'shift1', 'shift2', 'shift3']
            })
            this.dailyreportForm.patchValue({
                meter: finalarray
            })
        }).catch(error => {
            this.setState({ loader: false })
            apiService.ErrorMessage(error)
        })
    }

    processhandleChange(option) {
        if (option == '') {
            this.setState({
                selectedprocess: [],
            })
            this.shiftreportForm.patchValue({
                meter: []
            })
            this.pri_list = []
        } else {
            var test = false;
            for (let i = 0; i <= this.pri_list.length - 1; i++) {
                if (this.pri_list[i]['id'] == option['id']) {
                    test = true;
                    break;
                } else {
                    test = false;
                }
            }
            if (test == true) {
                this.pri_list = this.pri_list.filter((obj) => obj.id != option['id'])
            } else {
                this.pri_list.push(option)
            }
            this.setState({
                selectedprocess: [option.id],
            })
            this.shiftreportForm.patchValue({
                meter: [option.id]
            })
        }
    }

    daily_processhandleChange(option) {
        if (option == '') {
            this.setState({
                daily_selectedprocess: [],
            })
            this.dailyreportForm.patchValue({
                meter: []
            })
            this.daily_pri_list = []
        } else {
            var test = false;
            for (let i = 0; i <= this.daily_pri_list.length - 1; i++) {
                if (this.daily_pri_list[i]['id'] == option['id']) {
                    test = true;
                    break;
                } else {
                    test = false;
                }
            }
            if (test == true) {
                this.daily_pri_list = this.daily_pri_list.filter((obj) => obj.id != option['id'])
            } else {
                this.daily_pri_list.push(option)
            }
            this.setState({
                daily_selectedprocess: [option.id],
            })
            this.dailyreportForm.patchValue({
                meter: [option.id]
            })
        }
    }

    shifthandleChange(option) {
        if (option == '') {
            this.setState({
                selectedshift: ['General', 'shift1', 'shift2', 'shift3'],
            })
            this.shiftreportForm.patchValue({
                shift: ['General', 'shift1', 'shift2', 'shift3']
            })
        } else {
            var test = false;
            for (let i = 0; i <= this.shift_list.length - 1; i++) {
                if (this.shift_list[i]['value'] == option['value']) {
                    test = true;
                    break;
                } else {
                    test = false;
                }
            }
            if (test == true) {
                this.shift_list = this.shift_list.filter((obj) => obj.value != option['value'])
            } else {
                this.shift_list.push(option)
            }
            this.setState({
                selectedshift: [option.value],
            })
            this.shiftreportForm.patchValue({
                shift: [option.value]
            })
            console.log(this.state.selectedshift, this.shiftreportForm.getRawValue())
        }
    }


    toggle(option) {
        this.setState({
            activetab: option
        });
      }    
      convertdate1(e) {
        const sy = e.getFullYear();
        const sm = ("0" + (e.getMonth() + 1)).slice(-2);
        const sd = ("0" + e.getDate()).slice(-2);
        this.dailytoday = sy.toString() + '-' + sm.toString() + '-' + sd.toString();
        this.shiftreportForm.patchValue({
            date: this.dailytoday,
        })
        this.dailyreportForm.patchValue({
            start_date: this.dailytoday,
            end_date: this.dailytoday,
        })
    }

    handleshiftreportSubmit(e, formdata) {
        e.preventDefault();
        if (formdata['shift'].length == 0) {
            apiService.Alerts_error('Shift Field Required')
        } else if (formdata['meter'].length == 0) {
            apiService.Alerts_error('Meter Field Required')
        } else {
            this.setState({ loader: true })
            apiService.shiftreport(formdata).then(response => {
                this.setState({ loader: false })
                swal({
                    title: "Shift Report has been generated",
                    text: "The report is ready to download",
                    icon: "success",
                    buttons: true,
                    dangerMode: false,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            window.open(response.data.url);
                        } else {
                            // swal("Your file is safe!");
                        }
                    });
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }
    }

    handledailyreportSubmit(e, formdata) {
        e.preventDefault();
        if (formdata['meter'].length == 0) {
            apiService.Alerts_error('Meter Field Required')
        } else {
            formdata.start_time = formdata.start_time + ':00'
            formdata.end_time = formdata.end_time + ':00'
            apiService.dailyreport(formdata).then(response => {
                this.setState({ loader: false })
                swal({
                    title: "Daily Report has been generated",
                    text: "The report is ready to download",
                    icon: "success",
                    buttons: true,
                    dangerMode: false,
                })
                    .then((willDelete) => {
                        if (willDelete) {
                            window.open(response.data.url);
                        } else {
                            // swal("Your file is safe!");
                        }
                    });
            }).catch(error => {
                this.setState({ loader: false })
                apiService.ErrorMessage(error)
            })
        }
    }
    handleDailyProcessSelectAll = () => {
        const { isAllDailyProcessSelected } = this.state;
        const allIds = this.state.daily_production_process_list.map((option) => option.id);
        this.dailyreportForm.patchValue({
            meter: isAllDailyProcessSelected ? [] : allIds
        })
        this.daily_pri_list = []
        this.setState({
            daily_selectedprocess: isAllDailyProcessSelected ? [] : allIds,
            isAllDailyProcessSelected: !isAllDailyProcessSelected,
        });
    };
    handleShiftProcessSelectAll = () => {
        const { isAllShiftProcessSelected } = this.state;
        const allIds = this.state.production_process_list.map((option) => option.id);
        this.shiftreportForm.patchValue({
            meter: isAllShiftProcessSelected ? [] : allIds
        })
        //this.daily_pri_list = []
        this.setState({
            selectedprocess: isAllShiftProcessSelected ? [] : allIds,
            isAllShiftProcessSelected: !isAllShiftProcessSelected,
        });
    };
    handleChange = (event) => {
        const { value } = event.target;
// debugger;
        if (value.includes('all')) {
            this.handleDailyProcessSelectAll();
            return;
        }
        this.setState({ daily_selectedprocess: value, isAllDailyProcessSelected: false });
        if (this.state.daily_production_process_list.length == value.length) {
            this.setState({ isAllDailyProcessSelected: true });
        }
        this.dailyreportForm.patchValue({
            meter: value
        })
        
        // const value = event.target.value;

        // if (value[value.length - 1] === "all") {
        //   this.setState({
        //     daily_selectedprocess: this.state.daily_selectedprocess.length === this.state.daily_production_process_list.length ? [] : this.state.daily_production_process_list
        //   });
        //   return;
        // }
        // this.daily_production_process_list.map
        // this.setState({
        //   daily_selectedprocess: [this.state.daily_production_process_list.filter(s=>s.id==value)]
        // });
    };
    handleShiftProcessChange = (event) => {
        const { value } = event.target;

        if (value.includes('all')) {
            this.handleShiftProcessSelectAll();
            return;
        }
        
        this.setState({ selectedprocess: value, isAllShiftProcessSelected: false });
        if (this.state.production_process_list.length == value.length) {
            this.setState({ isAllShiftProcessSelected: true });
        }
        this.shiftreportForm.patchValue({
            meter: value
        })
        
    };
    // handleChange(event) {
    //     const value = event.target.value;
    //     if (value == '') {
    //         this.setState({
    //             daily_selectedprocess: [],
    //         })
    //         this.dailyreportForm.patchValue({
    //             meter: []
    //         })
    //         this.daily_pri_list = []
    //     } else {
    //         var test = false;
    //         for (let i = 0; i <= this.daily_pri_list.length - 1; i++) {
    //             if (this.daily_pri_list[i]['id'] == option['id']) {
    //                 test = true;
    //                 break;
    //             } else {
    //                 test = false;
    //             }
    //         }
    //         if (test == true) {
    //             this.daily_pri_list = this.daily_pri_list.filter((obj) => obj.id != option['id'])
    //         } else {
    //             this.daily_pri_list.push(option)
    //         }
    //         this.setState({
    //             daily_selectedprocess: [option.id],
    //         })
    //         this.dailyreportForm.patchValue({
    //             meter: [option.id]
    //         })
    //     }
    //     if (value[value.length - 1] === "all") {
    //         this.setState({
    //             daily_selectedprocess: this.state.daily_selectedprocess.length === this.state.daily_production_process_list.length ? [] : this.state.daily_production_process_list
    //         });
    //         return;
    //     }
    //     this.setState({
    //         daily_selectedprocess: [value]
    //     });

    // };
    render() {
        const { daily_selectedprocess, isAllDailyProcessSelected } = this.state;
        const { selectedprocess, isAllShiftProcessSelected } = this.state;
    const selectedNames = daily_selectedprocess.map(
      (selectedId) =>
        this.state.daily_production_process_list.find((option) => option.id === selectedId).itemName
    );
    const selectedProcessNames = selectedprocess.map(
        (selectedId) =>
          this.state.daily_production_process_list.find((option) => option.id === selectedId).itemName
      );
        const classes = ((theme) => ({
            formControl: {
                margin: theme.spacing(1),
                width: 300
            },
            indeterminateColor: {
                color: "#0044f5"
            },
            selectAllText: {
                fontWeight: 500
            },
            selectedAll: {
                backgroundColor: "#0044f5",
                "&:hover": {
                    backgroundColor: "#0044f5"
                }
            }
        }));
        return (
            <>
                <div className="content">
                    {
                        this.state.loader && <Loader />
                    }
                    <TopNavDropdown customerhandler={this.customerhandler} selectedcustomer={this.selectedcustomer} boardhandler={this.boardhandler} selectedboard={this.selectedboard} showProcessDropdown={true} />
                    {this.state.showcontent == true ?
                        <>
                            <Row>
                                <Col md="12">
                                    <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                        <CardHeader>
                                            <CardTitle tag="h5">
                                                <Nav tabs>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '1' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('1'); }}
                                                        >
                                                            Daily Report
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: this.state.activetab === '2' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                            onClick={() => { this.toggle('2'); }}
                                                        >
                                                            Shift Report
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activetab === '3' }) + ' ' + (this.props.theme) + ' ' + (this.props.themecolor)}
                                                        onClick={() => { this.toggle('3'); }}
                                                    >
                                                        Sequence Configuration
                                                    </NavLink>
                                                </NavItem>
                                                </Nav>
                                                <TabContent activeTab={this.state.activetab}></TabContent>                                              
                                            </CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <TabContent activeTab={this.state.activetab}>
                                                <TabPane tabId="1">
                                                    <FieldGroup
                                                        control={this.dailyreportForm}
                                                        strict={false}
                                                        render={({ get, invalid, value }) => (
                                                            <form>
                                                                <Row>
                                                                    <Col className="pr-1" md="2">
                                                                        <FieldControl
                                                                            name="start_date"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField type="date" required fullWidth variant="outlined" label="Start Date" error={touched && hasError("required")}  {...handler("text")} />
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* Start Date is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="2">
                                                                        <FieldControl
                                                                            name="end_date"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField type="date" required fullWidth variant="outlined" label="End Date" error={touched && hasError("required")}  {...handler("text")} />
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* End Date is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="2">
                                                                        <FieldControl
                                                                            name="start_time"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField type="time" required fullWidth variant="outlined" label="Start Time" error={touched && hasError("required")}  {...handler("text")} />
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* Start Time is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="2">
                                                                        <FieldControl
                                                                            name="end_time"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField type="time" required fullWidth variant="outlined" label="End Time" error={touched && hasError("required")}  {...handler("text")} />
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* End Time is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    {/* <Col className="pr-1" md="4">
                                                                        <FieldControl
                                                                            name="meter"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <FormControl variant="outlined"
                                                                                                margin={"1"}
                                                                                                style={{ width: "100%", marginBottom: 32 }}>
                                                                                                <InputLabel id="demo-mutiple-name-label30">Meter</InputLabel>
                                                                                                <Select
                                                                                                    labelId="demo-mutiple-name-label3"
                                                                                                    id="demo-mutiple-name-label3"
                                                                                                    fullWidth variant="outlined"
                                                                                                    multiple required
                                                                                                    label="Meter"
                                                                                                    value={this.state.daily_selectedprocess}
                                                                                                    {...handler("text")}
                                                                                                >
                                                                                                    {this.state.daily_production_process_list.map(option => (
                                                                                                        <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} onClick={(e) => this.daily_processhandleChange(option)}>
                                                                                                            {option.itemName}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </Select>
                                                                                            </FormControl>
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* Process is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col> */}
                                                                    <Col className="pr-1" md="2">
                                                                        <FormControl className={classes.formControl} style={{ width: "100%", marginBottom: 32 }}>
                                                                            <InputLabel id="mutiple-select-label">Meter</InputLabel>
                                                                            <Select
                                                                                labelId="mutiple-select-label"
                                                                                id="demo-mutiple-name-label3"
                                                                                fullWidth variant="outlined"
                                                                                multiple required
                                                                                label="Meter"
                                                                                onChange={this.handleChange}
                                                                                value={this.state.daily_selectedprocess}
                                                                                renderValue={() => selectedNames.join(', ')}
                                                                                
                                                                            >
                                                                                <MenuItem
                                                                                    value="all"
                                                                                    classes={{
                                                                                        root: this.state.isAllDailyProcessSelected ? classes.selectedAll : ""
                                                                                    }}
                                                                                >
                                                                                    <ListItemIcon>
                                                                                        <Checkbox
                                                                                            classes={{ indeterminate: classes.indeterminateColor }}
                                                                                            checked={this.state.isAllDailyProcessSelected}
                                                                                            indeterminate={
                                                                                                this.state.daily_selectedprocess.length > 0 && this.state.daily_selectedprocess.length < this.state.daily_production_process_list.length
                                                                                            }
                                                                                        />
                                                                                    </ListItemIcon>
                                                                                    <ListItemText
                                                                                        classes={{ primary: classes.selectAllText }}
                                                                                        primary="Select All"
                                                                                    />
                                                                                </MenuItem>
                                                                                {this.state.daily_production_process_list.map((option) => (
                                                                                    <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id}>
                                                                                        <ListItemIcon>
                                                                                            <Checkbox checked={this.state.daily_selectedprocess.includes(option.id)} />
                                                                                        </ListItemIcon>
                                                                                        <ListItemText primary={option.itemName} />
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col className="pr-1" md="4">
                                                                    </Col>
                                                                    <Col className="pr-1" md="4">
                                                                        <div className="login-button changepassword text-center">
                                                                            <Button
                                                                                type="submit"
                                                                                variant="contained"
                                                                                color="primary"
                                                                                disabled={invalid}
                                                                                onClick={(event) => this.handledailyreportSubmit(event, this.dailyreportForm.getRawValue())}
                                                                            >
                                                                                Generate
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="pr-1" md="4">
                                                                    </Col>
                                                                </Row>
                                                            </form>
                                                        )}
                                                    />
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <FieldGroup
                                                        control={this.shiftreportForm}
                                                        strict={false}
                                                        render={({ get, invalid, value }) => (
                                                            <form>
                                                                <Row>
                                                                    <Col className="pr-1" md="4">
                                                                        <FieldControl
                                                                            name="date"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <TextField type="date" required fullWidth variant="outlined" label="Date" error={touched && hasError("required")}  {...handler("text")} />
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* Date is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    <Col className="pr-1" md="4">
                                                                        <FieldControl
                                                                            name="shift"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <FormControl variant="outlined"
                                                                                                margin={"normal"}
                                                                                                style={{ width: "100%", marginBottom: 32 }}>
                                                                                                <InputLabel id="demo-mutiple-name-label300">Shift</InputLabel>
                                                                                                <Select
                                                                                                    labelId="demo-mutiple-name-label300"
                                                                                                    id="demo-mutiple-name-label300"
                                                                                                    fullWidth variant="outlined"
                                                                                                    multiple required
                                                                                                    label="Shift"
                                                                                                    value={this.state.selectedshift}
                                                                                                    {...handler("text")}
                                                                                                >
                                                                                                    {this.state.shiftlist.map(option => (
                                                                                                        <MenuItem className={"formfields shiftoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.value} value={option.value} onClick={(e) => this.shifthandleChange(option)}>
                                                                                                            {option.name}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </Select>
                                                                                            </FormControl>
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* Shift is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col>
                                                                    {/* <Col className="pr-1" md="4">
                                                                        <FieldControl
                                                                            name="meter"
                                                                            strict={false}
                                                                            render={({ handler, touched, hasError }) => (
                                                                                <div className={"formfields " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                                                                    <Grid className="Formfieldcss" container spacing={1}>
                                                                                        <Grid item>
                                                                                            <FormControl variant="outlined"
                                                                                                margin={"1"}
                                                                                                style={{ width: "100%", marginBottom: 32 }}>
                                                                                                <InputLabel id="demo-mutiple-name-label30">Meter</InputLabel>
                                                                                                <Select
                                                                                                    labelId="demo-mutiple-name-label30"
                                                                                                    id="demo-mutiple-name-label30"
                                                                                                    fullWidth variant="outlined"
                                                                                                    multiple required
                                                                                                    label="Meter"
                                                                                                    value={this.state.selectedprocess}
                                                                                                    {...handler("text")}
                                                                                                >
                                                                                                    {this.state.production_process_list.map(option => (
                                                                                                        <MenuItem className={"formfields meteroptions " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id} onClick={(e) => this.processhandleChange(option)}>
                                                                                                            {option.itemName}
                                                                                                        </MenuItem>
                                                                                                    ))}
                                                                                                </Select>
                                                                                            </FormControl>
                                                                                        </Grid>
                                                                                        <span className="error">
                                                                                            {touched
                                                                                                && hasError("required")
                                                                                                && `* Process is required`}
                                                                                        </span>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </Col> */}
                                                                    <Col className="pr-1" md="2">
                                                                        <FormControl className={classes.formControl} style={{ width: "120%", marginBottom: 32 }}>
                                                                            <InputLabel id="mutiple-select-label1">Meter</InputLabel>
                                                                            <Select
                                                                                labelId="mutiple-select-label1"
                                                                                id="demo-mutiple-name-label4"
                                                                                fullWidth variant="outlined"
                                                                                multiple required
                                                                                label="Meter"
                                                                                onChange={this.handleShiftProcessChange}
                                                                                value={this.state.selectedprocess}
                                                                                renderValue={() => selectedProcessNames.join(', ')}
                                                                                
                                                                            >
                                                                                <MenuItem
                                                                                    value="all"
                                                                                    classes={{
                                                                                        root: this.state.isAllShiftProcessSelected ? classes.selectedAll : ""
                                                                                    }}
                                                                                >
                                                                                    <ListItemIcon>
                                                                                        <Checkbox
                                                                                            classes={{ indeterminate: classes.indeterminateColor }}
                                                                                            checked={this.state.isAllShiftProcessSelected}
                                                                                            indeterminate={
                                                                                                this.state.selectedprocess.length > 0 && this.state.selectedprocess.length < this.state.production_process_list.length
                                                                                            }
                                                                                        />
                                                                                    </ListItemIcon>
                                                                                    <ListItemText
                                                                                        classes={{ primary: classes.selectAllText }}
                                                                                        primary="Select All"
                                                                                    />
                                                                                </MenuItem>
                                                                                {this.state.production_process_list.map((option) => (
                                                                                    <MenuItem className={"formfields processoptions " + (this.props.theme) + ' ' + (this.props.themecolor)} key={option.id} value={option.id}>
                                                                                        <ListItemIcon>
                                                                                            <Checkbox checked={this.state.selectedprocess.includes(option.id)} />
                                                                                        </ListItemIcon>
                                                                                        <ListItemText primary={option.itemName} />
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col className="pr-1" md="4">
                                                                    </Col>
                                                                    <Col className="pr-1" md="4">
                                                                        <div className="login-button changepassword text-center">
                                                                            <Button
                                                                                type="submit"
                                                                                variant="contained"
                                                                                color="primary"
                                                                                disabled={invalid}
                                                                                onClick={(event) => this.handleshiftreportSubmit(event, this.shiftreportForm.getRawValue())}
                                                                            >
                                                                                Generate
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="pr-1" md="4">
                                                                    </Col>
                                                                </Row>
                                                            </form>
                                                        )}
                                                    />
                                                </TabPane>
                                                <TabPane tabId="3">
                                                {this.state.activetab === '3' && (
                                                <ReportSequence 
                                                key={`sequence-${this.state.selectedCustomer}-${Date.now()}`}
                                                selectedcustomer={this.state.selectedCustomer}  // Change this
                                                theme={this.props.theme}
                                                themecolor={this.props.themecolor}
                                                history={this.props.history}
                                                activeTab={this.state.activetab}      />
  )}                                            </TabPane>                                            
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        :
                        <Row>
                            <Col md="12">
                                <Card className={"card-user " + (this.props.theme) + ' ' + (this.props.themecolor)}>
                                    <CardHeader>
                                    </CardHeader>
                                    <CardBody>
                                        <div style={{ width: "100%", height: "100px", fontSize: '22px', textAlign: 'center' }}>Please Choose atleast one Board to view the data ...</div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.theme,
        themecolor: state.themecolor,
        charttheme: state.charttheme
    }
}

export default connect(mapStateToProps)(Reports);